import {Controller} from "stimulus";
import {createApp, reactive, toRefs, watch, h, compile, onMounted, onUnmounted, ref, defineProps} from 'vue';
import {Toast} from "bootstrap/dist/js/bootstrap.bundle";

const queryBuilder = function (obj, prefix) {
  var str = [],
      p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
      str.push((v !== null && typeof v === "object") ?
          queryBuilder(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

/** @type {import('vue').Component} */
const CustomCard = {
  template: "#custom-card",
  props: {
    'projects': {
      default: []
    },
    'click': {
      default: () => ''
    },
    'customClass': {
      default: () => () => ''
    },
    'edit': {
      default: () => {
      }
    },
    'destroy': {
      default: () => {
      }
    }
  }
}

function isMac() {
  return !!navigator.userAgent.match(/mac|osx|ios|ipad|iphone/i)
}

function hasCommandOrCtrl(e) {
  return isMac() ? e.metaKey : e.ctrlKey
}

/** @type {import('vue').Component} */
const TabIframe = {
  props: {
    active: Boolean,
    src: String
  },

  setup(props) {
    const { active, src } = toRefs(props)
    const iframe = ref(null)
    const key = ref(Math.random().toString())

    onMounted(() => {
      const keydown = (e) => {
        if (e.key == 'Control') return

        if (hasCommandOrCtrl(e) && (e.key === 'r' || e.key === 'R')) {
          e.stopPropagation()
          e.preventDefault()

          if (confirm("您确定要载入当前标签吗？未保存的更改会丢失")) {
            e.target.ownerDocument.location.reload()

            setTimeout(() => {
              console.log('re-attach keydown listener')
              iframe.value.contentWindow.addEventListener('keydown', keydown)
              const { focusBlur } = iframe.value.contentWindow.parent
              if (focusBlur) {
                focusBlur.blur()
                focusBlur.focus()
              }
            }, 200)
          }
        }
      }

      if (window.scada_app) {
        if (iframe.value.contentWindow) {
          iframe.value.contentWindow.scada_app = window.scada_app
          iframe.value.contentWindow.addEventListener('keydown', keydown)
          console.log('iframe scada_app1 created')
          console.log('keydown listener is added')
        } else {
          iframe.value.addEventListener('load', () => {
            iframe.value.contentWindow.addEventListener('keydown', keydown)
            iframe.value.contentWindow.scada_app = window.scada_app
          }, { once: true })

          console.log('keydown listener is to be added on load')
        }
      }
    })

    return {
      key,
      iframe,
      src,
      active
    }
  },

  /*html*/
  template: `<iframe :src="src" ref="iframe" :class="{ active, 'tab-content': true }" />`,
}

export default class extends Controller {
  connect() {
    document.title = "项目列表 - SCADA"

    createApp({
      components: {
        CustomCard,
        TabIframe
      },
      setup() {
        const in_electron = "scada_app" in window
        const state = reactive({
          projects: [],
          recentProjects: [], // 最近打开的项目
          faceplateLibraries: [], //is_faceplate_library的projects
          activeTab: 'open',
          errorTip: '',
          params: {marked: 0, page: 1, per_page: 12, totalPage: 0},
          searchParams: {},

          /** @type {{name: string, url: string, active: boolean, type: 'home' | 'default'}[]} */
          applicationTabs: [{
            type: 'home',
            active: true
          }]
        })

        if (in_electron) {
          scada_app.receive('preferences_updated', () => {
            //创建项目后，刷新列表
            handleTabClick('mine');
          })
        }

        const TABS = [
          {
            label: '最近打开',
            value: 'open'
          },
          {
            label: '项目列表',
            value: 'mine'
          },
          {
            label: 'faceplate',
            value: 'faceplate'
          }
        ]

        /**
         * watch
         */
        // watch(state.searchParams, (aa) => {
        //   handleSearch()
        // })

        /**
         * methods
         */
        const debounce = (fn, waitTime = 500) => {
          let timer = null
          return function (...args) {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
              fn(args)
            }, waitTime)
          }
        }
        const showToast = (text) => {
          const toastLiveExample = document.getElementById('liveToast')
          const toast = new Toast(toastLiveExample)
          state.errorTip = text
          toast.show()
        }

        const fetchApi = async (api, params) => {
          if (params) {
            let query = queryBuilder(params)
            api = `${api}?${query}`
          }
          try {
            const r1 = await fetch(api, {headers: {Accept: 'application/json'}})
            const res = await r1.json()
            return res
          } catch (e) {
            console.warn(e)
            showToast(`${e}`)
            return null
          }
        }
        // 获取最近打开
        const getRecentProjects = async () => {
          const {rows} = await fetchApi("/admin/projects/recent", {page: 1, per_page: 15})
          state.recentProjects = rows
        }

        const fetchProjects = async (extraQuery = {}) => {
          const api_url = "/admin/projects"
          let params = {...state.params}
          // if (state.activeTab === 'open') {
          params = {...params, q: {...state.searchParams, ...extraQuery}}
          // }
          const {rows, total} = await fetchApi(api_url, params)
          return {rows, total}
        }

        // 获取所有项目
        const getAllProjects = async () => {
          const {rows, total} = await fetchProjects({is_faceplate_library_eq: false})
          // console.log('getAllProjects:', rows, total/state.params.per_page)
          state.projects = rows
          state.params.totalPage = Math.ceil(total / state.params.per_page)
        }

        // 获取所有项目
        const getFaceplateLibraries = async () => {
          const {rows, total} = await fetchProjects({is_faceplate_library_eq: true})
          // console.log('getAllProjects:', rows, total/state.params.per_page)
          state.faceplateLibraries = rows
          state.params.totalPage = Math.ceil(total / state.params.per_page)
        }

        // 搜索
        const handleSearch = debounce(() => {
          // state.params = { page: 1, per_page: 12, totalPage: 0 }
          if (state.activeTab === 'mine') {
            getAllProjects()
          } else {
            getFaceplateLibraries()
          }
        })

        // 切换页数
        const changePage = (val) => {
          if (val < 1 || val > state.params.totalPage || state.params.page == val) return
          state.params.page = val
          if (state.activeTab === 'mine') {
            getAllProjects()
          } else {
            getFaceplateLibraries()
          }
        }

        const handleTabClick = (val) => {
          state.activeTab = val
          const per_page = 12
          state.params = {page: 1, per_page, totalPage: 0}
          const fetchActionMap = {
            'mine': getAllProjects,
            'open': getRecentProjects,
            'faceplate': getFaceplateLibraries,
          }
          fetchActionMap[state.activeTab]?.()
        }

        const tabs = {
          /**
           * @param {{url: string, name: string}} param0
           */
          createOrActivate({name, url, reload = false}) {
            for (const tab of state.applicationTabs) {
              tab.active = false
            }

            const tab = state.applicationTabs.find((tab) => tab.url === url)
            if (tab) {
              tab.active = true
              if (reload) {
                setTimeout(() => {
                  document.querySelector('iframe.active')?.contentWindow?.location?.reload()
                }, 100)
              }
            } else {
              state.applicationTabs.push({name, url, active: true, type: 'default'})
            }

            setTimeout(() => {
              document.querySelector('iframe.active')?.contentWindow?.focus()
            }, 100)
          },

          close(url) {
            if (!url) {
              return
            }

            const index = state.applicationTabs.findIndex(tab => tab.url === url)
            if (index < 0) {
              return
            }

            state.applicationTabs.splice(index, 1)
            const activeIndex = state.applicationTabs.findIndex(tab => tab.active)
            if (activeIndex < 0) {
              state.applicationTabs[index - 1].active = true

              setTimeout(() => {
                document.querySelector('iframe.active')?.contentWindow?.focus()
              }, 100)
            }
          },

          activate(url) {
            const tab = state.applicationTabs.find((tab) => tab.url === url)
            if (tab) {
              state.applicationTabs.forEach(tab => tab.active = false)
              tab.active = true

              setTimeout(() => {
                document.querySelector('iframe.active')?.contentWindow?.focus()
              }, 100)

            }
          }
        }

        window.__tabControl ||= tabs

        const onDestroy = ({id}) => {
          confirm1({
            content: '确认删除该项目吗？', onOk: () => {
              const csrfToken = document.querySelector("[name='csrf-token']").content
              fetch(`/admin/projects/${id}`, {
                headers: {
                  Accept: 'application/json',
                  "X-CSRF-Token": csrfToken
                }, method: "DELETE"
              })
                  .then((res) => res.json())
                  .then(() => {
                    getAllProjects();
                    getRecentProjects();
                  })
            }
          })
        }

        /**
         * @param {{id: string, name: string}} param0
         * @param {Event} e
         */
        const goDetail = ({id, name}, e) => {
          // location.href = in_electron ? `/draw/?project_id=${id}` : `/admin/projects/${id}/open`
          // location.href = `/draw/v2/#/projects/${id}`;
          const newWindow = hasCommandOrCtrl(e) || e.button === 1
          const url = `/draw/v2/${newWindow ? '' : '?in_tab=1'}#/projects/${id}`;

          if (newWindow) {
            window.open(url, '_blank')
          } else {
            tabs.createOrActivate({name: "🖊️" + name, url})
          }
        }

        const goPreferences = ({id, name}, e) => {
          // window.open(`//${location.host}/preferences/?project_id=${id}`)
          // window.open(`/admin/projects/${id}/open`)
          // window.open(in_electron ? `${location.host}/preferences/?project_id=${id}` : `${location.host}/admin/projects/${id}/open`)
          const url = `//${location.host}/preferences/?project_id=${id}`
          const newWindow = hasCommandOrCtrl(e) || e.button === 1

          if (newWindow) {
            window.open(url, '_blank')
          } else {
            tabs.createOrActivate({name: "⚙️" + name, url})
          }
          e.stopPropagation()
        }

        const goNew = () => {
          // window.open(`/admin/projects/new`, '_blank')
          window.open(`//${location.host}/preferences/#/project/create`, 'New Project', 'popup, width=600,height=800')
        }

        const onIframeLoad = (e) => {
          if (window.scada_app) {
            console.log('iframe scada_app created')
            e.target.contentWindow.scada_app = window.scada_app

            const draggableElement = document.querySelector('nav.header')
            if (draggableElement) {
              draggableElement.style.appRegion = "no-drag";
              setTimeout(() => {
                draggableElement.style.appRegion = "drag";
              }, 10)
            }
          }


        }

        /**
         * created
         */
        getRecentProjects()

        onMounted(() => {
          window.addEventListener('keydown', (event) => {
            if (event.ctrlKey &&  (event.key === 'r' || event.key === 'R')) {
              event.preventDefault()
              event.stopPropagation()
              console.log('control + r pressed on the parent frame')
            }
          })
        })

        onUnmounted(() => {

        })


        return {
          ...toRefs(state),
          TABS,
          handleTabClick,
          changePage,
          getAllProjects,
          goDetail,
          goPreferences,
          handleSearch,
          in_electron,
          goNew,
          onDestroy,
          onIframeLoad,
          tabs
        }
      }
    }).mount(this.element)
  }
}
